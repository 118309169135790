import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import classnames from "classnames";
import { useNavigate, useParams } from "react-router-dom";
import "./DynamicFormComponent.css";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import TableContainer from "../../components/Common/TableContainer";
import * as Yup from "yup";
import { useFormik } from "formik";
import { jobs } from "../../common/data";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import DeleteModal from "../../components/Common/DeleteModal";

import {
  getJobList as onGetJobList,
  addNewJobList as onAddNewJobList,
  updateJobList as onUpdateJobList,
  deleteJobList as onDeleteJobList,
} from "store/actions";

import {
  JobNo,
  JobTitle,
  CompanyName,
  Location,
  Experience,
  Position,
  Type,
  PostedDate,
  LastDate,
  Status,
} from "pages/JobPages/JobList/JobListCol";

import { useSelector, useDispatch } from "react-redux";

import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import {
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  FormGroup,
  Button,
} from "reactstrap";
import { useDropzone } from "react-dropzone";
import ImageUploadComponent from "./ImageUploadComponent";
import MyMultiSelectComponent from "./MyMultiselectComponent";
import MyMultiSelectPointAComponent from "./MyMultiselectPointAComponent";
import MyMultiSelectPointBComponent from "./MyMultiselectPointBComponent";

function EditRoute_Pricing() {
  const navigate = useNavigate();
  const { id: editrouteid } = useParams();
  const [activeTabVartical, setoggleTabVertical] = useState(1)
  const [passedStepsVertical, setPassedStepsVertical] = useState([1])
    const [routeDetails, setrouteDetails] = useState([]);
   const [isLoading, setIsLoading] = useState(false)
  const [productName, setProductName] = useState("");
  const [countryId, setCountryId] = useState("");
  const [viewfromaddress, setViewFromAddress] = useState([]); // Ensure it's initialized as an array
  const [viewtoaddress, setViewToAddress] = useState([]); // Ensure it's initialized as an array

  const [stateId, setStateId] = useState("");
  const [isNextDisabled, setIsNextDisabled] = useState(true);
  const [cityId, setCityId] = useState("");
  const [tourType, setTourType] = useState("");
  const [tourType2, setTourType2] = useState("");
  const [Pickupinfo, setPickupinfo] = useState("");
  const [pointA, setPointA] = useState([]);
  const [pointB, setPointB] = useState([]);

  const [deletedAddresses, setDeletedAddresses] = useState([]); // ✅ Store removed addresses

  const [formData, setFormData] = useState([
    {
      vehicleId: "",
      tourType: "",
      addressType: "A To B",
      fromAddress: "",
      toAddress: "",
      routePrice: "",
      meet: "",
      water: "",
    },
    {
      vehicleId: "",
      tourType: "",
      addressType: "B To A",
      fromAddress: "", // Prepopulated dynamically
      toAddress: "", // Prepopulated dynamically
      routePrice: "",
      meet: "",
      water: "",
    },
  ]);
  const [viewvehicle, setVehicles] = useState([]);
  const [countries, setCountries] = useState([]);
  const [statesies, setStates] = useState([]);
  const [citiess, setCitiess] = useState([]);
  const [isTab1Valid, setIsTab1Valid] = useState(true); // ✅ Default to true so Tab 1 works
  const [isTab2Valid, setIsTab2Valid] = useState(false); // ✅ Default to false, validates when user reaches Tab 2
  

  const fetchFromAddress = async () => {
    try {
      const response = await fetch(
        "https://login.flymiles.com.au/api_sections/transafer/get_pickup_destination_by_route.php",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ route_id: editrouteid, type: "pickup" }),
        }
      );
      let data = await response.json();
      console.log("Fetched From Address Data:", data);
  
      setViewFromAddress(Array.isArray(data) ? data : []); // Ensure array format
    } catch (error) {
      console.error("Error fetching from address:", error);
      setViewFromAddress([]); // Prevent undefined errors
    }
  };

  useEffect(() => {
    if (activeTabVartical === 1) {
      const isValidTab1 = formData.every(item =>
        item.vehicleId && item.tourType && item.routePrice && item.routePrice !== "0"
      );
      setIsTab1Valid(isValidTab1); // ✅ Validate only Tab 1
    }
  
    if (activeTabVartical === 2) {
      const isValidTab2 = formData.every(item =>
        item.vehicleId && item.tourType && item.routePrice && item.routePrice !== "0"
      );
      setIsTab2Valid(isValidTab2); // ✅ Validate only Tab 2
    }
  }, [formData, activeTabVartical]); // ✅ Runs only when `formData` or `activeTabVartical` changes
  
  const fetchToAddress = async () => {
    try {
      const response = await fetch(
        "https://login.flymiles.com.au/api_sections/transafer/get_pickup_destination_by_route.php",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ route_id: editrouteid, type: "destination" }),
        }
      );
      let data = await response.json();
      console.log("Fetched To Address Data:", data);
  
      setViewToAddress(Array.isArray(data) ? data : []); // Ensure array format
    } catch (error) {
      console.error("Error fetching to address:", error);
      setViewToAddress([]); // Prevent undefined errors
    }
  };
  
  
   
   useEffect(() => {
     if (editrouteid) {
       fetchFromAddress();
       fetchToAddress();
     }
   }, [editrouteid]);


      const fetchcompleteroutedetail = async () => {
        const routecompete = {
          route_id: editrouteid,
        };
        try {
          const response = await fetch(
            "https://login.flymiles.com.au/api_sections/transafer/get_route_complete_detail.php",
            {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(routecompete),
            }
          );
          const data = await response.json();
          console.log(data);
    
          setProductName(data.Product_Header.route_name);
          setCountryId(data.Product_Header.country_id);
          fetchStates(data.Product_Header.country_id); // Ensure states are loaded
          setStateId(data.Product_Header.state_id);
          fetchCities(data.Product_Header.state_id); // Ensure cities are loaded
          setCityId(data.Product_Header.city_id);
          setTourType(data.Product_Header.transfer_type);
          setPickupinfo(data.Product_Header.pickup_instructions);
    
          const pointA = data.route_detail
          .filter((detail) => detail.type === "pickup")
          .map((detail) => ({ value: detail.id.toString(), label: detail.address }));
        
        const pointB = data.route_detail
          .filter((detail) => detail.type === "destination")
          .map((detail) => ({ value: detail.id.toString(), label: detail.address }));
        
        setPointA(pointA);
        setPointB(pointB);
        
        console.log("Point A Data:", pointA);
        console.log("Point B Data:", pointB);
    
    
          setPointA(pointA);
          setPointB(pointB);
    
          const pricing = data.route_pricing.map((pricing) => ({
            vehicleId: pricing.vehicle_id,
            tourType:pricing.trasnfer_type,
            addressType: pricing.address_type,
            fromAddress: pricing.from_add,
            toAddress: pricing.to_add,
            routePrice: pricing.price,
            meet: pricing.meet_greet,
            water: pricing.water_bottle,
          }));
          console.log("form data");
          console.log(pricing);
          setFormData(pricing);
        } catch (error) {
          console.error("Error fetching route details:", error);
        }
      };
      

  useEffect(() => {
    setIsNextDisabled(pointA.length === 0 || pointB.length === 0);
  }, [pointA, pointB]);

  useEffect(() => {
    fetchcompleteroutedetail();
  }, []);

  const addMore = () => {
    setFormData([
      ...formData,
      {
        vehicleId: "",
        tourType: "",
        addressType: "A To B",
        fromAddress: "",
        toAddress: "",
        routePrice: "",
        meet: "",
        water: "",
      },
      {
        vehicleId: "",
        tourType: "",
        addressType: "B To A",
        fromAddress: "", // Prepopulated dynamically
        toAddress: "", // Prepopulated dynamically
        routePrice: "",
        meet: "",
        water: "",
      },
    ]);
  };

  const fetchvehicles = async () => {
    const vehicledata = { vehicles: "Yes", own_page: "Yes" };
    try {
      const response = await fetch(
        "https://login.flymiles.com.au/api_sections/transafer/get_vehicle.php",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(vehicledata),
        }
      );
      const data = await response.json();
      setVehicles(data || []);
    } catch (error) {
      console.error("Error fetching vehicles:", error);
    }
  };

  useEffect(() => {
    fetchvehicles();
  }, []);

  const fetchCountries = async () => {
    try {
      const response = await fetch(
        "https://login.flymiles.com.au/api_sections/transafer/get_country_state_city_list.php",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ get_country: "Yes", own_page: "yes" }),
        }
      );
      const data = await response.json();
      setCountries(data || []);
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  const fetchStates = async (cid) => {
    try {
      const response = await fetch(
        "https://login.flymiles.com.au/api_sections/transafer/get_state_by_country.php",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ country_id: cid }),
        }
      );
      const data = await response.json();
      setStates(Array.isArray(data) ? data : []);
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };

  const fetchCities = async (sid) => {
    try {
      const response = await fetch(
        "https://login.flymiles.com.au/api_sections/transafer/get_city_by_state.php",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ state_id: sid }),
        }
      );
      const data = await response.json();
      setCitiess(Array.isArray(data) ? data : []);
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  };

  const removePoint = (type, value) => {
    let removedAddress = "";

    // ✅ Find actual address before removing
    if (type === "fromAddress") {
      const found = viewfromaddress.find(addr => addr.address === value);
      removedAddress = found ? found.address : value; // Fallback to `value` if not found
    } else if (type === "toAddress") {
      const found = viewtoaddress.find(addr => addr.address === value);
      removedAddress = found ? found.address : value; // Fallback to `value` if not found
    }
  
    console.log("Removed Address:", removedAddress);
  
    // ✅ Store removed address
    setDeletedAddresses(prev => [...prev, removedAddress]);

    if (type === "fromAddress") {
      setPointA(prev => prev.filter(item => item.value !== value)); 
      setViewFromAddress(prev => prev.filter(addr => addr.address !== value)); 
  
      setFormData(prevFormData => prevFormData.map(item => ({
        ...item,
        fromAddress: item.fromAddress.includes(value)
          ? item.fromAddress.split(',').filter(addr => addr !== value).join(',')
          : item.fromAddress,
        toAddress: item.toAddress.includes(value)
          ? item.toAddress.split(',').filter(addr => addr !== value).join(',')
          : item.toAddress
      })));
    } else if (type === "toAddress") {
      setPointB(prev => prev.filter(item => item.value !== value)); 
      setViewToAddress(prev => prev.filter(addr => addr.address !== value)); 
  
      setFormData(prevFormData => prevFormData.map(item => ({
        ...item,
        toAddress: item.toAddress.includes(value)
          ? item.toAddress.split(',').filter(addr => addr !== value).join(',')
          : item.toAddress,
        fromAddress: item.fromAddress.includes(value)
          ? item.fromAddress.split(',').filter(addr => addr !== value).join(',')
          : item.fromAddress
      })));
    }
  };
  
  
  

  const handlerouteChange = (index, field, value) => {
    console.log(`Before change [index: ${index}, field: ${field}]:`, formData);
  
    setFormData(prevFormData => {
      const updatedFormData = prevFormData.map((item, i) => {
        if (i === index) {
          return { ...item, [field]: value };
        }
  
        // ✅ Ensure B to A always mirrors A to B in reverse
        if (index % 2 === 0 && i === index + 1) {
          return {
            ...item,
            addressType: "B To A", // ✅ Fix address type
            fromAddress: field === "toAddress" ? value : prevFormData[index].toAddress,
            toAddress: field === "fromAddress" ? value : prevFormData[index].fromAddress,
            tourType: prevFormData[index].tourType, // ✅ Sync tourType
            vehicleId: prevFormData[index].vehicleId, // ✅ Sync vehicleId
            meet: prevFormData[index].meet, // ✅ Sync meet
            water: prevFormData[index].water, // ✅ Sync water
            // ❌ DO NOT mirror routePrice, keep existing value
          };
        }
  
        return item;
      });
     
      console.log(`After change [index: ${index}, field: ${field}]:`, updatedFormData);
      return updatedFormData;
    });
  };
  
  
  
  

  const handlepointChange = (selectedCities, point) => {
    if (point === "pointA") {
      setPointA(selectedCities);
    } else if (point === "pointB") {
      setPointB(selectedCities);
    }
  };
  
  useEffect(() => {
    // Log pointA and pointB whenever they change
    console.log("PointA:", pointA);
    console.log("PointB:", pointB);
  
    // Update routeDetails whenever either pointA or pointB changes
    if (pointA.length || pointB.length) {
      setrouteDetails(prevRouteDetails => [
        ...pointA.map(city => ({
          type: "pickup",
          address: city.label,
        })),
        ...pointB.map(city => ({
          type: "destination",
          address: city.label,
        })),
      ]);
    }
  }, [pointA, pointB]); // This hook runs when pointA or pointB changes
  
  useEffect(() => {
    console.log("RouteDetails:", routeDetails);
  }, [routeDetails]);

  function toggleTabVertical(tab) { 
    if (activeTabVartical !== tab) {
      var modifiedSteps = [...passedStepsVertical, tab]

      if (tab >= 1 && tab <= 9) {
        setoggleTabVertical(tab)
        setPassedStepsVertical(modifiedSteps)
      }
    }
  }

  const handleSubmit = async () => {
    if (pointA.length === 0 || pointB.length === 0) {
      alert("Please select at least one Point A and one Point B.");
      return;
    }
  
    console.log("Submitting Route Details:", routeDetails);
  
    // Update viewfromaddress and viewtoaddress
    setViewFromAddress(pointA.map(point => ({ type: "pickup", address: point.label })));
    setViewToAddress(pointB.map(point => ({ type: "destination", address: point.label })));
  
    // Update formData with selected points
    setFormData(prevFormData => {
      const updatedFormData = [...prevFormData];
  
      pointA.forEach((point, index) => {
        if (updatedFormData[index]) {
          updatedFormData[index].fromAddress = point.label;
        }
      });
  
      pointB.forEach((point, index) => {
        if (updatedFormData[index]) {
          updatedFormData[index].toAddress = point.label;
        }
      });
  
      return updatedFormData;
    });
    const deletedData = {
      route_id: editrouteid,
      deleted_address: deletedAddresses, // ✅ Array of removed addresses
    };
    console.log(deletedAddresses);
    console.log(deletedData);
    try {
      const response = await fetch(
        "https://login.flymiles.com.au/api_sections/transafer/update_route_pricing_next.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(deletedData),
        }
      );
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const result = await response.json();
      console.log("Success Next Api:", result);
      const pricing = result.route_pricing.map((pricing) => ({
        vehicleId: pricing.vehicle_id,
        tourType:pricing.trasnfer_type,
        addressType: pricing.address_type,
        fromAddress: pricing.from_add,
        toAddress: pricing.to_add,
        routePrice: pricing.price,
        meet: pricing.meet_greet,
        water: pricing.water_bottle,
      }));
      console.log("form data new");
      console.log(pricing);
      setFormData(pricing);
  
  
    } catch (error) {
     
    }
  
    // Construct API payload
    const updateFormData = {
      route_id: editrouteid,
      route_name: productName,
      country_id: countryId,
      state_id: stateId,
      city_id: cityId,
      transfer_type: tourType,
      route_details: routeDetails, // This should be correctly updated before sending
    };
  
    console.log("Sending API Request:", updateFormData);
  
    setIsLoading(true);
  
    try {
      const response = await fetch(
        "https://login.flymiles.com.au/api_sections/transafer/update_route.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updateFormData),
        }
      );
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const result = await response.json();
      console.log("Success:", result);
  
      setIsLoading(false);
      
      // Ensure state updates before switching to Tab 2
      setTimeout(() => {
        toggleTabVertical(2);
      }, 100);
  
    } catch (error) {
      setIsLoading(false);
      console.error("Error:", error);
      alert("Failed. Please try again.");
    }
  };
  
  useEffect(() => {
    const isValid = formData.every(item =>
      item.vehicleId && item.tourType && item.routePrice && item.routePrice !== "0"
    );
    setIsNextDisabled(!isValid);
  }, [formData]); // ✅ Runs every time formData updates
  
  
 
  
  

  const handlerouteSubmit = async () => {
    setIsLoading(true);
  
    // ✅ Ensure formData is fully updated before using it
    await new Promise(resolve => setTimeout(resolve, 100));
  
    setFormData(prevFormData => {
      const finalFormData = prevFormData.map(item => ({
        vehicle_id: item.vehicleId || "0",
        address_type: item.addressType || "A To B",
        from_add: item.fromAddress || "",
        to_add: item.toAddress || "",
        price: item.routePrice || "0",
        meet_greet: item.meet || "",
        trasnfer_type: item.tourType || "",
        water_bottle: item.water || "",
      }));
  
      console.log("Final formData before API call:", finalFormData);
      
      sendToAPI(finalFormData);
  
      return prevFormData; // ✅ Keep the original state unchanged
    });
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://login.flymiles.com.au/api_sections/transafer/get_route_details.php");
        const json = await response.json();
        console.log("API Response:", json);
  
        if (json && json.data) {
          const updatedFormData = json.data.map((item) => ({
            vehicleId: item.vehicle_id || "0",
            tourType: item.trasnfer_type || "",
            addressType: item.address_type || "A To B",
            fromAddress: item.from_add || "", // ✅ Ensure this pre-fills correctly
            toAddress: item.to_add || "", // ✅ Ensure this pre-fills correctly
            routePrice: item.price || "0",
            meet: item.meet_greet || "",
            water: item.water_bottle || "",
          }));
  
          setFormData(updatedFormData);
        }
      } catch (error) {
        console.error("Error fetching route details:", error);
      }
    };
  
    fetchData();
  }, []);
  
  
  
  // ✅ Separate API function to ensure correct data submission
  const sendToAPI = async (finalFormData) => {
    const updateFormData = {
      route_id: editrouteid,
      pickup_instructions: Pickupinfo,
      price_detail: finalFormData,
    };
  
    console.log("Sending to API:", updateFormData);
  
    try {
      const response = await fetch(
        "https://login.flymiles.com.au/api_sections/transafer/update_route_pricing.php",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(updateFormData),
        }
      );
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const result = await response.json();
      console.log("Success:", result);
      navigate("/allroutes");
      setIsLoading(false);
      toggleTabVertical(activeTabVartical + 1);
    } catch (error) {
      setIsLoading(false);
      console.error("Error during API call:", error);
      alert("Failed. Please try again.");
    }
  };
  
 
  
  
return (
  <React.Fragment>
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Transfers" breadcrumbItem="Edit Route" />
        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <h4 className="card-title mb-4">Edit Route</h4>
                <div className="vertical-wizard wizard clearfix vertical">
                  <div className="steps clearfix">
                    <ul>
                      <NavItem
                        className={classnames({
                          current: activeTabVartical === 1,
                        })}
                      >
                        <NavLink
                          className={classnames({
                            active: activeTabVartical === 1,
                          })}
                          onClick={() => {
                            toggleTabVertical(1)
                          }}
                          disabled={!(passedStepsVertical || []).includes(1)}
                        >
                          <span className="number">1.</span> Route Details
                        </NavLink>
                      </NavItem>
                      <NavItem
                        className={classnames({
                          current: activeTabVartical === 2,
                        })}
                      >
                        <NavLink
                          className={classnames({
                            active: activeTabVartical === 2,
                          })}
                          onClick={() => {
                            toggleTabVertical(2)
                          }}
                          disabled={!(passedStepsVertical || []).includes(2)}
                        >
                          <span className="number">2.</span>{" "}
                          <span>Route Pricing</span>
                        </NavLink>
                      </NavItem>

                    </ul>
                  </div>
                  <div className="content clearfix">
                    <TabContent
                      activeTab={activeTabVartical}
                      className="body"
                    >
                      <TabPane tabId={1}>
                      <Form>
                          <Row>
                            <Col lg="6">
                              <FormGroup className="mb-3">
                                <Label>Route Name</Label>
                                <Input
                                  type="text"
                                  value={productName}
                                  onChange={(e) => setProductName(e.target.value)}
                                  placeholder="Enter Route Name"
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup className="mb-3">
                                <Label>Transfer Type</Label>
                                <select
                                  className="form-control"
                                  value={tourType}
                                  onChange={(e) => setTourType(e.target.value)}
                                >
                                  <option value="">Select Transfer Type</option>
                                  <option value="shared">Shared</option>
                                  <option value="private">Private</option>
                                </select>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <FormGroup className="mb-3">
                                <Label>Country</Label>
                                <select
                                  className="form-select"
                                  value={countryId}
                                  onChange={(e) => {
                                    setCountryId(e.target.value);
                                    fetchStates(e.target.value);
                                  }}
                                >
                                  <option>Select Country</option>
                                  {countries.map((country) => (
                                    <option key={country.id} value={country.id}>
                                      {country.country}
                                    </option>
                                  ))}
                                </select>
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup className="mb-3">
                                <Label>State</Label>
                                <select
                                  className="form-select"
                                  value={stateId}
                                  onChange={(e) => {
                                    setStateId(e.target.value);
                                    fetchCities(e.target.value);
                                  }}
                                >
                                  <option>Select State</option>
                                  {statesies.map((state) => (
                                    <option key={state.id} value={state.id}>
                                      {state.state}
                                    </option>
                                  ))}
                                </select>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <FormGroup className="mb-3">
                                <Label>City</Label>
                                <select
                                  className="form-select"
                                  value={cityId}
                                  onChange={(e) => setCityId(e.target.value)}
                                >
                                  <option>Select City</option>
                                  {citiess.map((city) => (
                                    <option key={city.id} value={city.id}>
                                      {city.city}
                                    </option>
                                  ))}
                                </select>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <FormGroup className="mb-3">
                                <Label>Point A</Label>
                                <MyMultiSelectPointAComponent
                                  id={cityId}
                                  data={pointA}
                                  onChange={(selectedCities) => handlepointChange(selectedCities, "pointA")}
                                  removePoint={removePoint}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup className="mb-3">
                                <Label>Point B</Label>
                                <MyMultiSelectPointBComponent
                                  id={cityId}
                                  data={pointB}
                                  onChange={(selectedCities) => handlepointChange(selectedCities, "pointB")}
                                  removePoint={removePoint}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </Form>
                      </TabPane>
                     
                      <TabPane tabId={2}>
                        <Form>
                          {/* Loop through all formData entries to render forms */}
                          {formData.map((data, index) => (
                            // Wrap the entire A to B and B to A routes in a bordered container
                            (index % 2 === 0) && (
                              <div key={index} className="dynamicform-with-border">
                                <div className="dynamicform">
                                  {/* A to B Route */}
                                  <>
                                    <Row>
                                      <Col lg="6">
                                        <FormGroup className="mb-3">
                                          <Label>Vehicle</Label>
                                          <select
                                            className="form-select"
                                            value={data.vehicleId || ""}
                                            onChange={(e) => handlerouteChange(index, "vehicleId", e.target.value)}
                                            required
                                          >
                                            <option value="">Select Vehicle</option>
                                            {viewvehicle?.map((vehicle) => (
                                              <option key={vehicle.vehicle_Header.id} value={vehicle.vehicle_Header.id}>
                                                {vehicle.vehicle_Header.vehicle_type_des}
                                              </option>
                                            ))}
                                          </select>
                                        </FormGroup>
                                      </Col>
                                      <Col lg="6">
                                        <FormGroup className="mb-3">
                                          <Label>Transfer Type</Label>
                                          <select
                                            className="form-control"
                                            value={data.tourType || ""}
                                            onChange={(e) => {
                                              console.log("Selected tourType:", e.target.value);
                                              handlerouteChange(index, "tourType", e.target.value);
                                             
                                            }}
                                            required
                                          >
                                            <option value="">Select Transfer Type</option>
                                            <option value="shared">Shared</option>
                                            <option value="private">Private</option>
                                          </select>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col lg="12">
                                        <h5 class="atob">Route: A to B</h5>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col lg="4">
                                        <FormGroup className="mb-3">
                                          <Label>From Address</Label>
                                          <select
                                            className="form-select"
                                            value={data.fromAddress || ""}
                                            onChange={(e) => handlerouteChange(index, "fromAddress", e.target.value)}
                                          >
                                            <option value="">Select From Address</option>
                                            {viewfromaddress.map((fromadress, idx) => (
                                              <option
                                                key={`${fromadress.type}-${fromadress.address}-${idx}`}
                                                value={fromadress.address}
                                              >
                                                {fromadress.address}
                                              </option>
                                            ))}
                                          </select>

                                        </FormGroup>
                                      </Col>
                                      <Col lg="4">
                                        <FormGroup className="mb-3">
                                          <Label>To Address</Label>
                                          <select
                                            className="form-select"
                                            value={data.toAddress || ""}
                                            onChange={(e) => handlerouteChange(index, "toAddress", e.target.value)}
                                          >
                                            <option value="">Select To Address</option>
                                            {viewtoaddress.map((toadress, idx) => (
                                              <option
                                                key={`${toadress.type}-${toadress.address}-${idx}`}
                                                value={toadress.address}
                                              >
                                                {toadress.address}
                                              </option>
                                            ))}
                                          </select>

                                        </FormGroup>
                                      </Col>
                                      <Col lg="4">
                                        <FormGroup className="mb-3">
                                          <Label>Price</Label>
                                          <Input
                                            type="number"
                                            className="form-control"
                                            value={data.routePrice || ""}
                                            onChange={(e) => handlerouteChange(index, "routePrice", e.target.value)}
                                            required
                                            min={1}
                                            placeholder="Enter Route Price"
                                          />
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                    {formData[index].tourType === "private" ? (
                                      <Row>
                                        <Col lg="4">
                                          <FormGroup className="mb-3">
                                            <Label>Meet Greet</Label>
                                            <select
                                              className="form-control"
                                              value={formData[index].meet || ""}
                                              onChange={(e) => handlerouteChange(index, "meet", e.target.value)}
                                            >
                                              <option value="">Select Option</option>
                                              <option value="yes">Yes</option>
                                              <option value="no">No</option>
                                            </select>
                                          </FormGroup>
                                        </Col>
                                        <Col lg="4">
                                          <FormGroup className="mb-3">
                                            <Label>Water Bottle</Label>
                                            <select
                                              className="form-control"
                                              value={formData[index].water || ""}
                                              onChange={(e) => handlerouteChange(index, "water", e.target.value)}
                                            >
                                              <option value="">Select Option</option>
                                              <option value="yes">Yes</option>
                                              <option value="no">No</option>
                                            </select>
                                          </FormGroup>
                                        </Col>
                                      </Row>
                                    ) : (
                                      <div style={{ visibility: 'hidden', color: "red" }}>tourType is not private</div>
                                    )}
                                  </>
                                </div>

                                {/* B to A Route */}
                                {index + 1 < formData.length && (
                                  <div className="dynamicform">
                                    <Row>
                                      <Col lg="12">
                                        <h5 class="btoa">Route: B to A</h5>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col lg="4">
                                        <FormGroup className="mb-3">
                                          <Label>From Address</Label>
                                          <select
                                            className="form-select"
                                            disabled
                                            value={formData[index + 1].fromAddress || ""}
                                            onChange={(e) => handlerouteChange(index + 1, "fromAddress", e.target.value)}
                                          >
                                            <option value="">{formData[index].toAddress || "Select From Address"}</option>
                                          </select>
                                        </FormGroup>
                                      </Col>
                                      <Col lg="4">
                                        <FormGroup className="mb-3">
                                          <Label>To Address</Label>
                                          <select
                                            className="form-select"
                                            disabled
                                            value={formData[index + 1].toAddress || ""}
                                            onChange={(e) => handlerouteChange(index + 1, "toAddress", e.target.value)}
                                          >
                                            <option value="">{formData[index].fromAddress || "Select To Address"}</option>
                                          </select>
                                        </FormGroup>
                                      </Col>
                                      <Col lg="4">
                                        <FormGroup className="mb-3">
                                          <Label>Price</Label>
                                          <Input
                                            type="number"
                                            className="form-control"
                                            value={formData[index + 1].routePrice || ""}
                                            onChange={(e) => handlerouteChange(index + 1, "routePrice", e.target.value)}
                                            placeholder="Enter Route Price"
                                          />
                                        </FormGroup>
                                      </Col>
                                    </Row>

                                    {formData[index + 1].tourType === "private" ? (
                                      <Row>
                                        <Col lg="4">
                                          <FormGroup className="mb-3">
                                            <Label>Meet Greet</Label>
                                            <select
                                              className="form-control"
                                              value={formData[index + 1].meet || ""}
                                              onChange={(e) => handlerouteChange(index + 1, "meet", e.target.value)}
                                            >
                                              <option value="">Select Option</option>
                                              <option value="yes">Yes</option>
                                              <option value="no">No</option>
                                            </select>
                                          </FormGroup>
                                        </Col>
                                        <Col lg="4">
                                          <FormGroup className="mb-3">
                                            <Label>Water Bottle</Label>
                                            <select
                                              className="form-control"
                                              value={formData[index + 1].water || ""}
                                              onChange={(e) => handlerouteChange(index + 1, "water", e.target.value)}
                                            >
                                              <option value="">Select Option</option>
                                              <option value="yes">Yes</option>
                                              <option value="no">No</option>
                                            </select>
                                          </FormGroup>
                                        </Col>
                                      </Row>
                                    ) : (
                                      <div style={{ visibility: 'hidden', color: "red" }}>tourType is not private</div>
                                    )}
                                  </div>
                                )}
                              </div>
                            )
                          ))}
                        </Form>
                        <Row>
                          <Col>
                            <FormGroup className="mb-3">
                              <Label>Pickup Instructions</Label>
                              <Input
                                type="text"
                                required
                                className="form-control"
                                onChange={(e) => setPickupinfo(e.target.value)}
                                value={Pickupinfo}
                                id="basicpill-firstname-input12"
                                placeholder="Enter Pickup Instructions"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </TabPane>
                     
                    </TabContent>
                  </div>
                  <div className="actions clearfix">
                    <ul>
                      {activeTabVartical < 8 ? (
                        <>
                          <li>
                            {activeTabVartical === 2 && (
                              <Link
                                className="addbtn"
                                to="#"
                                onClick={() => { addMore(); }}
                              >
                                Add More
                              </Link>
                            )}
                          </li>
                          <li
                            className={
                              activeTabVartical === 1
                                ? "previous disabled"
                                : "previous"
                            }
                          >
                            <Link
                              to="#"
                              onClick={() => {
                                toggleTabVertical(activeTabVartical - 1)
                              }}
                            >
                              Previous
                            </Link>
                          </li>
                        </>
                      ) : (
                        <></>
                      )}
                      <li className={(activeTabVartical === 2 && !isTab2Valid) ? "next disabled" : "next"}>
                        <Link
                          to="#"
                          onClick={(e) => {
                            if (activeTabVartical === 2 && !isTab2Valid) {
                              e.preventDefault(); // ✅ Prevent clicking on Tab 2 if validation fails
                              return;
                            }

                            if (activeTabVartical === 1) {
                              handleSubmit();
                            } else if (activeTabVartical === 2) {
                              handlerouteSubmit();
                            }
                          }}
                          disabled={activeTabVartical === 2 && !isTab2Valid} // ✅ Only disable on Tab 2
                        >
                          {isLoading ? "Loading..." : "Next"}
                        </Link>
                      </li>

                    </ul>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  </React.Fragment>
)
}

export default EditRoute_Pricing;
