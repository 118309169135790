import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";

function MyMultiSelectPointAComponent({ onChange, id, data, removePoint }) {
  console.log(id);
  const [cities, setCities] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  const prevIdRef = useRef();

  useEffect(() => {
    const fetchCity = async () => {
      try {
        const response = await fetch(
          "https://login.flymiles.com.au/api_sections/transafer/get_pickup_destination_for_route_by_city.php",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              city_id: id || null,
              pickuponly: "yes",
            }),
          }
        );
        const json = await response.json();
        console.log("API Response:", json);

        const cityOptions = Array.isArray(json)
          ? json.map(city => ({
              value: city.id.toString(),
              label: city.landmark,
            }))
          : json.data?.map(city => ({
              value: city.id.toString(),
              label: city.landmark,
            })) || [];
          
        setCities(cityOptions);
      } catch (error) {
        console.error("Error fetching cities:", error);
      }
    };

    if (prevIdRef.current !== undefined && prevIdRef.current !== id) {
      setSelectedOption([]);
    }

    fetchCity();
    prevIdRef.current = id;
  }, [id]);

  useEffect(() => {
    if (data && data.length > 0) {
      const selectedOptions = data.map((city) => ({
        value: city.value.toString(),
        label: city.label,
      }));
      setSelectedOption(selectedOptions);
    }
  }, [data]);

  // **Updated onChange to Detect Removals and Additions**
  const handleSelectionChange = (selectedOptions) => {
    // **Detect Removed Items**
    const removedOptions = selectedOption.filter(
      option => !selectedOptions.some(selected => selected.value === option.value)
    );

    // Call removePoint function to update the next tab
    removedOptions.forEach(option => {
      removePoint("fromAddress", option.label);
    });

    setSelectedOption(selectedOptions);
    onChange(selectedOptions);
  };

  return (
    <Select
      isMulti
      name="pointA"
      options={cities}
      className="basic-multi-select"
      classNamePrefix="select"
      onChange={handleSelectionChange} // ✅ Handles additions & removals
      value={selectedOption}
      placeholder="Select Point A"
    />
  );
}

export default MyMultiSelectPointAComponent;
